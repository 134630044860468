import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formValues, setFormValues] = useState({ ptitle: '', pprice: '', pfile: '' });
  const [message, setMessage] = useState('');

  const handleInput = (e) => {
    setFormValues({ ...formValues, [e.target.ptitle]: e.target.value });
  }

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`https://backend.aihelth.com/api/product.php/${id}`);
        setFormValues(response.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    }

    fetchProductData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { id, ...formValues };

    try {
      const response = await axios.put("http://localhost/reactcrudphp/api/product.php", formData);
      
      if (response.data.success) {
        setMessage(response.data.success);
        setTimeout(() => {
          navigate('/productlist');
        }, 2000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-8 mt-4">
            <h5 className="mb-4">Edit Product</h5>
            <p className="text-warning">{message}</p>

            <form onSubmit={handleSubmit}>
              <div className="mb-3 row">
                <label className="col-sm-3">Product Title </label>
                <div className="col-sm-9">
                  <input type="text" className="form-control" onChange={handleInput} name="ptitle" value={formValues.ptitle} />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-sm-3">Product Price </label>
                <div className="col-sm-9">
                  <input type="text" className="form-control" onChange={handleInput} name="pprice" value={formValues.pprice} />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-sm-3">Product Image</label>
                <div className="col-sm-9">
                  <input type="file" className="form-control" onChange={handleInput} name="pfile" value={formValues.pfile} />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-sm-3"></label>
                <div className="col-sm-9">
                  <button type="submit" className="btn btn-success">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditProduct;
